// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-body-categories-detail-categories-detail-js": () => import("./../../../src/components/body/categoriesDetail/CategoriesDetail.js" /* webpackChunkName: "component---src-components-body-categories-detail-categories-detail-js" */),
  "component---src-components-body-offer-detail-offer-detail-js": () => import("./../../../src/components/body/offerDetail/OfferDetail.js" /* webpackChunkName: "component---src-components-body-offer-detail-offer-detail-js" */),
  "component---src-components-body-page-usine-usine-page-js": () => import("./../../../src/components/body/pageUsine/UsinePage.js" /* webpackChunkName: "component---src-components-body-page-usine-usine-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-page-js": () => import("./../../../src/pages/HomePage.js" /* webpackChunkName: "component---src-pages-home-page-js" */),
  "component---src-pages-pre-js": () => import("./../../../src/pages/PRE.js" /* webpackChunkName: "component---src-pages-pre-js" */),
  "component---src-pages-thank-you-page-js": () => import("./../../../src/pages/ThankYouPage.js" /* webpackChunkName: "component---src-pages-thank-you-page-js" */)
}

